
@media only screen and (orientation: portrait) {
  .image-vitrine--container{
    width: 100%;
    display: block;
    margin: auto;
  }
  .image-vitrine{
    margin: auto;
    width: 100%;
    display: block;
  }
  .image-parent-s{
    position: relative;
    width: 10vmin;
    height: 10vmin;
  }
  .image-s{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 9.6vmin;
    height: 9.6vmin;
  }
  .image-parent-xs{
    position: relative;
    width: 5vmin;
    height: 5vmin;
  }
  .image-xs{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 4.8vmin;
    height: 4.8vmin;
  }
  .image-parent-xxs{
    position: relative;
    width: 3.5vmin;
    height: 3.5vmin;
  }
  .image-xxs{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 3.3vmin;
    height: 3.3vmin;
  }
  .image-parent{
    position: relative;
    width: 15vmin;
    height: 15vmin;
  }
  .image{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 14.6vmin;
    height: 14.6vmin;
  }
  .image-parent-x2{
    position: relative;
    width: 30vmin;
    height: 30vmin;
  }
  .image-x2{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 29.2vmin;
    height: 29.2vmin;
  }

  .image-parent-x3{
    position: relative;
    width: 40vmin;
    height: 40vmin;
  }
  .image-x3{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 39.2vmin;
    height: 39.2vmin;
  }
}
.image-vitrine--container:focus, .image-vitrine:focus{
  outline: none;
}
@media only screen and (orientation: landscape) {
  .image-vitrine--container{
    height: 30vh;
    width: 100%;
    display: block;
  }
  .image-vitrine{
    margin: auto;
    height: 29.5vh;
    padding-top: 0.25vh;
    display: block;
  }
  .image-parent-s{
    position: relative;
    width: 7vmin;
    height: 7vmin;
  }
  .image-s{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 6.7vmin;
    height: 6.7vmin;
  }
  .image-parent-xs{
    position: relative;
    width: 4vmin;
    height: 4vmin;
  }
  .image-xs{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 3.8vmin;
    height: 3.8vmin;
  }
  .image-parent-xxs{
    position: relative;
    width: 2.5vmin;
    height: 2.5vmin;
  }
  .image-xxs{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 2.3vmin;
    height: 2.3vmin;
  }
  .image-parent{
    position: relative;
    width: 10vmin;
    height: 10vmin;
  }
  .image{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 9.6vmin;
    height: 9.6vmin;
  }
  .image-parent-x2{
    position: relative;
    width: 20vmin;
    height: 20vmin;
  }
  .image-x2{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 19.2vmin;
    height: 19.2vmin;
  }

  .image-parent-x3{
    position: relative;
    width: 30vmin;
    height: 30vmin;
  }
  .image-x3{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 29.2vmin;
    height: 29.2vmin;
  }
}