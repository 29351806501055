.login-fields{
  width: 80%;
  margin: 3% 10%;
}
.login--text-span{
  width: 80%;
  margin: 0 11%;
}

.message--top{
  position: fixed;
  top : 0;
  left: 50%;
  margin-top: 1vh;
  transform: translate(-50%,0);
}

@media (min-width:1023px) {
  position: fixed;
  top : 0;
  left: 50%;
  transform: translate(-50%,0);
  margin-top: 1vh;
}

.login-background {
  background-image: url("../../image/login_background_portret.svg");
  background-color: #ffffff;
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
}
.login-container{
  width: 90%;
}
.login-container-wide{
  width: 96%;
}

@media (min-width:1023px) {
  .login-background {
    background-image: url("../../image/login_background_landscape.svg");
    background-color: #ffffff;
    background-position: center; /* Center the image */
    background-repeat: no-repeat;
  }
  .login-container {
    width: 33%;
  }
  .login-container-wide{
    width: 50%;
  }
}


